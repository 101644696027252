import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { ModalProvider } from "styled-react-modal"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Article, ArticleSection, ArticleHeader, ArticleDate, ArticleH1 } from "../../styledComponents/article"
import { FadingBackground } from "../../components/modalFadingBackground"
import {
    Section
} from "../../styledComponents/section"
import { HeroTextImageWrapper, HeroText, HeroHeader, HeroDescription } from "../../styledComponents/heroBanner"
import Modal from "../../components/modal"
import Icon from "../../components/icon"

const TechSeo = ({ data, location, createCookie }) => {

    return (
        <Layout location={location}>
            <Seo
                title="Technical SEO optimising your website or app for Search Engines"
                description="Technical SEO involves optimising a website to improve search engine performance and user experience."
                image=""
                url="/services/search-engine-optimisation"
            />
            <HeroTextImageWrapper backgroundColor="var(--light-blue-mid)" gridRowGap="0">
                <StaticImage
                    alt=""
                    loading="eager"
                    layout="fullWidth"
                    src={
                        "../../images/seo.jpg"
                    }
                // formats={["auto", "webp", "avif"]}
                />
                <HeroText fontColor="var(--white)">
                    <HeroHeader level={1}>
                        Search Engine Optimisation (SEO)
                    </HeroHeader>
                    <HeroDescription>Technical SEO involves optimising a website to improve search engine performance and user experience.</HeroDescription>
                </HeroText>
            </HeroTextImageWrapper>

            <Article
                itemScope
                itemType="http://schema.org/Article"
            >
                <ArticleHeader>
                    <ArticleH1 itemProp="headline">Technical SEO: Optimizing Your Website for Search Engines</ArticleH1>
                    <ArticleDate offScreen pubdate dateTime=""><span className="publishedon">Published on</span> 28th October 2020</ArticleDate>
                    {/* <ShareTools shareTitle="Share this on" pageLocation="" postTitle="" /> */}
                </ArticleHeader>
                <ArticleSection>

                    <h2>Why is Technical SEO Important?</h2>
                    <p>Technical SEO is essential to ensure your website appears in search results and gains traffic, revenue and business growth. It impacts your website's performance on Google and helps to improve user experience.</p>

                    <h3>Common Tasks Associated with Technical SEO:</h3>
                    <p>To optimise your website for technical SEO, some common tasks include:</p>

                    <ul>
                        <li>Submitting your sitemap to Google</li>
                        <li>Creating an SEO-friendly site structure</li>
                        <li>Improving your website's speed</li>
                        <li>Making your website mobile-friendly</li>
                        <li>Finding and fixing duplicate content issues</li>
                    </ul>
                    <p>And much more</p>

                    <h3>Understanding Crawling:</h3>
                    <p>To optimise your website for technical SEO, it is crucial to understand crawling. Crawling is the process by which search engines discover and index web pages.</p>

                    <StaticImage
                        backgroundcolor="#002F4A"
                        alt=""
                        layout="fullWidth"
                        src={
                            "../../images/how-google-search-engine-works.png"
                        }
                        formats={["auto", "webp", "avif"]}
                    />

                    <p>Technical SEO is essential to improve search engine performance and user experience. By optimising your website's technical aspects, you can increase your website's visibility, gain more traffic and ultimately grow your business.</p>

                    <p>Crawling happens when search engines follow links on pages they already know about to find pages they haven't seen before.</p>

                    <p>For example, every time we publish new blog posts, we add them to our blog archive page.</p>

                    <p>So the next time a search engine like Google crawls our blog page, it sees the recently added links to new blog posts.</p>

                    <p>And that's one of the ways Google discovers our new blog posts.</p>

                    <p>If you want your pages to show up in search results, you first need to ensure that they are accessible to search engines.</p>

                    <p>There are a few ways to do this:</p>

                    <h2>Create SEO-Friendly Site Architecture</h2>

                    <p>Site architecture, also called site structure, is the way pages are linked together within your site.</p>

                    <p>An effective site structure organizes pages in a way that helps crawlers find your website content quickly and easily.</p>

                    <p>So when structuring your site, ensure all the pages are just a few clicks away from your homepage.</p>

                    <p>Like so:</p>


                    <StaticImage
                        backgroundcolor="#002F4A"
                        layout="fullWidth"
                        alt=""
                        src={
                            "../../images/seo-friendly-site-architecture.png"
                        }
                        formats={["auto", "webp", "avif"]}
                    />

                    <p>In the site structure above, all the pages are organized in a logical hierarchy.</p>

                    <p>The homepage links to category pages. And then, category pages link to individual subpages on the site.</p>

                    <p>This structure also reduces the number of orphan pages.</p>

                    <p>Orphan pages are pages with no internal links pointing to them, making it difficult (or sometimes impossible) for crawlers and users to find those pages.</p>



                </ArticleSection>
            </Article>

            <Section background="var(--mid-blue-mid)" type="pullout">
                <h2>68% of online experiences begin with a search engine.</h2>
            </Section>

            <Article>

                <ArticleSection>

                    <h2>Submit Your Sitemap to Google</h2>
                    <p>Using a sitemap can help Google find your webpages.</p>

                    <p>A sitemap is typically an XML file containing a list of important pages on your site. It lets search engines know which pages you have and where to find them.</p>

                    <p>Which is especially important if your site contains a lot of pages. Or if they're not well-linked together.</p>

                    <p>Here's what TICG's sitemap looks like:</p>


                    {/*  */}

                    <p>Your sitemap is usually located at one of these two URLs:</p>
                    <ul>
                        <li>yoursite.com/sitemap.xml</li>
                        <li>yoursite.com/sitemap_index.xml</li>
                    </ul>

                    <p>Once you locate your sitemap, submit it to Google via GSC (Google Search Console).</p>
                    <p>To submit your sitemap to Google, go to GSC and click “Indexing” &gt; “Sitemaps” from the sidebar. </p>

                    <p>Then, paste your sitemap URL in the blank and hit “Submit.”</p>

                    <StaticImage
                        backgroundcolor="#002F4A"
                        layout="fullWidth"
                        alt=""
                        src={
                            "../../images/add-new-sitemap.jpg"
                        }
                        formats={["auto", "webp", "avif"]}
                    />


                    <p>After Google is done processing your sitemap, you should see a confirmation message like this:</p>

                    <StaticImage
                        backgroundcolor="#002F4A"
                        layout="fullWidth"
                        alt=""
                        src={
                            "../../images/sitemap-submitted.png"
                        }
                        formats={["auto", "webp", "avif"]}
                    />


                    <h2>Understanding Indexing</h2>
                    <p>Once search engines crawl your pages, they then try to analyze and understand the content of those pages.</p>

                    <p>And then the search engine stores those pieces of content in its search index—a huge database containing billions of webpages.</p>

                    <p>The pages of your site must be indexed by search engines to appear in search results.</p>

                    <p>The simplest way to check if your pages are indexed is to perform a “site:” search.</p>


                    <p>A few things can keep Google from indexing your webpages:</p>

                    <h2>Noindex Tag</h2>
                    <p>The “noindex” tag is an HTML snippet that keeps your pages out of Google's index.</p>

                    <p>It's placed within the &lt;head&gt; section of your webpage and looks like this:</p>

                    <code>
                        <p>&lt;meta name="robots" content="noindex"&gt;</p>
                    </code>

                    <p>Ideally, you would want all your important pages to get indexed. So use the “noindex” tag only when you want to exclude certain pages from indexing. </p>

                    <p>These could be:</p>
                    <ul>
                        <li>“Thank you” pages</li>
                        <li>PPC landing pages</li>
                    </ul>

                    <p>To learn more about using “noindex” tags and how to avoid common implementation mistakes, read our guide to robots meta tags.</p>

                    <h2>Canonicalization</h2>
                    <p>When Google finds similar content on multiple pages on your site, it sometimes doesn't know which of the pages to index and show in search results. </p>

                    <p>That's when canonical tags come in handy.</p>

                    <p>The canonical tag (rel="canonical") identifies a link as the original version, which tells Google which page it should index and rank.</p>

                    <p>The tag is nested within the &lt;head&gt; of a duplicate page and looks like this:</p>

                    <code>
                        <p>&lt;link rel="canonical" href="https://example.com/original-page/" /&gt;</p>
                    </code>




                </ArticleSection>
            </Article>

            <Section background="var(--mid-blue-mid)" type="pullout">
                <h2>Google (+ Google Images) currently holds 92.58% of the total search engine market share, followed by bing, Yahoo!, Baidu and YANDEX.</h2>
            </Section>


            <Article>

                <ArticleSection>
                    <h2>Technical SEO Best Practices</h2>
                    <p>Creating an SEO-friendly site structure and submitting your sitemap to Google should get your pages crawled and indexed.</p>

                    <p>But if you want your website to be fully optimized for technical SEO, consider these additional best practices.</p>

                    <h2>1. Use HTTPS</h2>
                    <p>HTTPS is a secure version of HTTP.</p>

                    <p>It helps protect sensitive user information like passwords and credit card details from being compromised.</p>

                    <p>And it's been a ranking signal <a href="https://developers.google.com/search/blog/2014/08/https-as-ranking-signal">since 2014</a>.</p>

                    <p>You can check whether your site uses HTTPS by simply visiting it.</p>

                    <p>Just look for the “lock” icon in the address bar to confirm.</p>

                    <p>If you see the “Not secure” warning, you're not using HTTPS.</p>

                    <p>In this case, you need to install an SSL certificate.</p>

                    <p>An SSL certificate authenticates the identity of the website. And establishes a secure connection when users are accessing it.</p>

                    <p>You can get an SSL certificate for free from Let's Encrypt.</p>

                    <p>Important: Once your website moves over to HTTPS, ensure you add redirects from HTTP to the HTTPS version of your website. This will redirect all the users visiting your HTTP version to the secure, HTTPS version of your site.</p>


                    <h2>2. Make Sure Only One Version of Your Website Is Accessible to Users and Crawlers</h2>
                    <p>Users and crawlers should only be able to access one of these two versions of your site:</p>
                    <ul>
                        <li>https://yourwebsite.com</li>
                        <li>https://www.yourwebsite.com</li>
                    </ul>


                    <p>Having both versions accessible creates duplicate content issues.</p>

                    <p>And reduces the effectiveness of your backlink profile—some websites may link to the “www” version, while others link to the “non-www” version.</p>

                    <p>This can negatively affect your performance in Google.</p>

                    <p>So only use one version of your website. And redirect the other version to your main website.</p>

                    <h2>3. Improve Your Page Speed</h2>
                    <p>Page speed is a ranking factor both on mobile and desktop.</p>

                    <p>So make sure your site loads as fast as possible. </p>

                    <p>You can use Google's PageSpeed Insights tool to check your website's current speed.</p>

                    <p>It gives you a performance score from 0 to 100. The higher the number, the better.</p>

                    <p>Here're few ideas for improving your website speed:</p>

                    <ul className="process-ticks single-column">
                        <li><Icon name="circle-tick" />Compress your images – Images are usually the biggest files on a webpage. Compressing them with image optimization tools like Shortpixel will reduce their file size so they take as little time to load as possible.</li>
                        <li><Icon name="circle-tick" />Use CDN (content distribution network) – CDN stores copies of your webpages on servers around the globe. It then connects visitors to the nearest server, so there's less distance for the requested files to travel. </li>
                        <li><Icon name="circle-tick" />Minify HTML, CSS and JavaScript files – Minification removes unnecessary characters and whitespace from code to reduce file sizes. Which improves page load time.</li>
                    </ul>


                </ArticleSection>
            </Article>

            <Section background="var(--mid-blue-mid)" type="pullout">
                <h2>92.1% of internet users access the internet using a mobile phone.</h2>
            </Section>

            <Article>
                <ArticleSection>
                    <h2>4. Ensure Your Website Is Mobile-Friendly</h2>
                    <p>Google uses mobile-first indexing. This means that it looks at mobile versions of webpages to index and rank content.</p>
                    <p>So make sure your website is compatible on mobile devices.</p>
                    <p>To check if that's the case for your site, head over to the “Mobile Usability” report in Google Search Console.</p>

                    <StaticImage
                        backgroundcolor="#002F4A"
                        layout="fullWidth"
                        alt=""
                        src={
                            "../../images/mobile-usability-issues.png"
                        }
                        formats={["auto", "webp", "avif"]}
                    />

                    <p>The report shows you the number of pages that affect mobile usability.</p>

                    <p>Along with specific issues.</p>
                    <StaticImage
                        backgroundcolor="#002F4A"
                        layout="fullWidth"
                        alt=""
                        src={
                            "../../images/mobile-usability-list-issues.png"
                        }
                        formats={["auto", "webp", "avif"]}
                    />

                    <p>If you don't have Google Search Console, you can use <a href="https://search.google.com/test/mobile-friendly">Google's Mobile-Friendly Test tool.</a></p>
                    <StaticImage
                        backgroundcolor="#002F4A"
                        // layout="fullWidth"
                        alt=""
                        src={
                            "../../images/google-mobile-page-test.png"
                        }
                        formats={["auto", "webp", "avif"]}
                    />


                    <h2>5. Implement Structured Data</h2>
                    <p>Structured data helps Google better understand the content of a page.</p>

                    <p>And by adding the right structured data markup code, your pages can win rich snippets.</p>

                    <p>Rich snippets are more appealing search results with additional information appearing under the title and description.</p>

                    <p>Example:</p>

                    <StaticImage
                        backgroundcolor="#002F4A"
                        // layout="fullWidth"
                        alt=""
                        className="noMargin"
                        src={
                            "../../images/structured-data-example.png"
                        }
                        formats={["auto", "webp", "avif"]}
                    />

                    <p>The benefit of rich snippets is that they make your pages stand out from others. Which can improve your CTR (click-through rate).</p>

                    <p>Google supports dozens of structured data markups, so choose one that best fits the nature of the pages you want to add structured data to.</p>

                    <p>For example, if you run an ecommerce store, adding product structured data to your product pages makes sense.</p>

                    <p>There are plenty of free structured data generator tools like <a href="https://schemantra.com/">this one</a>, so you don't have to write the code by hand.</p>

                    <p>And if you're using WordPress, you can use the <a href="https://yoast.com/help/implementing-schema-with-yoast-seo/">Yoast SEO plugin to implement structured data.</a></p>

                    <h2>6. Optimize for Core Web Vitals</h2>
                    <p>Core Web Vitals are speed metrics that Google uses to measure user experience. </p>
                    <p>These metrics include:</p>
                    <ul>
                        <li>Largest Contentful Paint (LCP) – Calculates the time a webpage takes to load its largest element for a user</li>
                        <li>First Input Delay (FID) – Measures the time it takes to react to a user's first interaction with a webpage.</li>
                        <li>Cumulative Layout Shift (CLS) – Measures the shifts in layouts of various elements present on a webpage</li>
                    </ul>
                    <p>To ensure your website is optimized for Core Web Vitals, you need to aim for the following scores:</p>

                    <ul>
                        <li>LCP –2.5 sec or lower</li>
                        <li>FID – 100 ms or lower</li>
                        <li>CLS – 0.1 or lower </li>
                    </ul>
                    <p>You can check your website’s performance for Core Web Vitals metrics in Google Search Console.</p>

                    <p>To do this, visit the Core Web Vitals report in your Search Console.</p>


                </ArticleSection>
            </Article>
            <HeroTextImageWrapper
                backgroundColor="var(--orange-mid)"
            >
                <StaticImage
                    layout="fullWidth"
                    alt=""
                    src={
                        "../../images/contact-us-lets-talk.jpg"
                    }
                    formats={["auto", "webp", "avif"]}
                    className="padding1"
                />
                <HeroText fontColor="var(--white)">
                    <HeroHeader level={1}>Need help with your Technical SEO</HeroHeader>
                    <HeroDescription>Get in contact and fill in our form we can review your .</HeroDescription>
                    <ModalProvider backgroundComponent={FadingBackground}>
                        <Modal
                            title="Contact Us"
                            type="hero"
                            serviceName="seo"
                            buttonLabel="Get your webistes SEO reviewed"
                            buttonStyle="HeroButton"
                            formExcerpt="Please fill in the following form and one of our team will get back to you within 24 hours."
                        />
                    </ModalProvider>
                </HeroText>
            </HeroTextImageWrapper>

        </Layout>
    )
}

export default TechSeo
